svg{
    width:2%;
    height:auto;
    margin-right: 2%;
    padding-right: 2%;
    color:#f1f1f1;
  }
  .pra-footer{
    position:fixed;
    width: 100%;
    text-align:center;
    justify-content: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
  }